import React from "react"

import * as embedStyle from "./embed.module.css"

export default function Embed(props) {
	return (
		<iframe src={`/consent${props.final_src}`} className={embedStyle.embed} title="Embedded page content"
			frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="true">
		</iframe>
	)
}
