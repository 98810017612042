import React from "react"
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import { graphql } from "gatsby"

import Note from "../components/note"
import Embed from "../components/embed"
import Page from "../components/page"

export default function Stream({ data, pageContext }) {
	const stream = data.markdownRemark.frontmatter
	return (
		<>
			<Page title={stream.event}>
				<Container fluid="lg">
					<Col className="col-lg-12">
						<div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
						<Note variant="warning" text={stream.note} />
						{data.slug}
					</Col>
				</Container>
				
				<center>
					<Embed final_src={pageContext.slug} />
				</center>
			</Page>
		</>
	);
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				event
		date
		embedurl
		note
			}
		}
	}
`
