import React from "react"
import Alert from 'react-bootstrap/Alert';

export default function Note(props) {
	if (!props.text || !props.text.length) {
		return <></>
	} else {
		return (
			<>
				<Alert variant={props.variant}>{props.text}</Alert>
			</>
		);
	}
}